@import "../../../../../var.scss";

.residentCardContacts {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px 24px 72px 24px;
    background: $white;
    box-shadow: 0px 0px 20px -3px rgba(216, 216, 226, 0.2);
    border-radius: 16px;

    > .newFamilyMember {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 8px;

        > .adminIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 5px;
            margin-left: 8px;
            background-color: $primary01;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $white;
    
            &:hover {
                cursor: pointer;
                background-color: $hover01;
            }
        }
    }

    > .contactContainer {
        > .contact {
            display: flex;
            flex-direction: column;
    
            > .header {
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                > .title {
                    display: flex;
                    align-items: center;
    
                    > .text {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: $darkGray;
                        margin-right: 10px;
                    }
    
                    > .icon {
    
                    }
                }
    
                > .writeComment {
                    display: flex;
    
                    &:hover {
                        cursor: pointer;
                    }
    
                    > .icon {
                        margin-right: 11px;
                    }
    
                    > .text {
                        font-size: 14px;
                        line-height: 24px;
                        color: $primary01;
                    }
                }
            }
    
            > .name {
                display: flex;
                justify-content: space-between;
                margin-top: 16px;
    
                > .text {
                    display: flex;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: $darkGray;

                    > .confirmedIcon {
                        margin-left: 4px;
                    }
                }
    
                > .adminActions {
                    display: flex;
    
                    > .editFamilyMember {
                        display: flex;
                        align-items: center;
                        margin-right: 16px;
        
                        &:hover {
                            cursor: pointer;
                            color: $hover01;
                        }
        
                        > .icon {
                            margin-right: 5px;
                        }
        
                        > .text {
                            color: $primary01;
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
        
                    > .deleteFamilyMember {
                        display: flex;
                        align-items: center;
        
                        &:hover {
                            cursor: pointer;
                        }
        
                        > .icon {
                            margin-right: 5px;
                        }
        
                        > .text {
                            color: $deleteRed;
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
                }
    
            }
    
            > .checked {
                display: flex;
                margin-top: 16px;
    
                > .icon {
                    margin-right: 10px;
                }
    
                > .text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: $darkGray;
                }
            }
    
            > .infoRows {
                margin: 16px 0;
    
                > .infoRow {
                    display: flex;
                    justify-content: space-between;
    
                    &:not(:first-child) {
                        margin-top: 16px;
                    }
    
                    > .title {
                        font-size: 16px;
                        line-height: 24px;
                        color: #7E808F;
                    }
    
                    > .value {
                        text-align: right;
                        font-size: 16px;
                        line-height: 24px;
                        color: $darkGray;
                    }
                }
            }
    
            > .comment {
                position: relative;
                padding: 12px 16px;
                background: $backgroundLightRed;
                border-radius: 16px;
                border: 1px solid $borderRed;

                > .confirm {
                    > .icon {
                        position: absolute;
                        top: -10px;
                        right: -10px;
                        border: 1px solid $white;
        
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
    
    
                > .text {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: $darkGray;
                    font-style: italic;
                    
                    > .red {
                        color: $statusHigh;
                    }
                }
            }
        }
    
        > .line {
            height: 1px;
            background-color: $borderLightGray;
            margin: 24px 0;
        }
    }

    > .seeAllContacts {
        position: absolute;
        bottom: 16px;
        width: calc(100% - 48px);
        display: flex;
        align-items: center;
        flex-direction: column;

        > .line {
            width: 100%;
            height: 1px;
            background-color: $borderLightGray;
            margin: 24px 0;
        }

        > .btn {
            font-size: 14px;
            line-height: 24px;
            color: $primary01;

            &:hover {
                cursor: pointer;
                color: $hover01;
            }
        }
    }
}

.modalComment {
    > .modal {
        width: 100%;
        display: flex;
        flex-direction: column;
        
        > .title {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: $darkGray;
        }
    
        > .form {
            > .input {
                margin-top: 24px;
                min-height: 96px !important;
            }
        
            > .bottom {
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
                
                > .saveBtn {
                    height: 48px;
                    width: 218px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: $white;
                    text-align: center;
                    background-color: $borderActive;
                    border: 1px solid $borderActive;
                }
            }
        }
    }
}

.modalContacts {
    overflow: hidden;
    padding: 10px;

    > .modal {
        max-height: 400px;
        padding: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;

        &_scrollable {
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 4px;
              background-color: rgb(225, 222, 222);
            }
          
            &::-webkit-scrollbar-thumb {
              background-color: #3956C7;
              border-radius: 8px;
            }
        }

        > .title {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: $darkGray;
        }
        
        > .familyRelations {
            margin-top: 24px;

            > .familyRelation {
                > .name {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 16px;

                    > .text {
                        display: flex;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: $darkGray;

                        > .confirmedIcon {
                            margin-left: 4px;
                        }
                    }
        
                    > .adminActions {
                        display: flex;
                        align-items: center;
        
                        > .editFamilyMember {
                            display: flex;
                            align-items: center;
                            margin-right: 16px;
            
                            &:hover {
                                cursor: pointer;
                                color: $hover01;
                            }
            
                            > .icon {
                                margin-right: 5px;
                            }
            
                            > .text {
                                color: $primary01;
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }
            
                        > .deleteFamilyMember {
                            display: flex;
                            align-items: center;
            
                            &:hover {
                                cursor: pointer;
                            }
            
                            > .icon {
                                margin-right: 5px;
                            }
            
                            > .text {
                                color: $deleteRed;
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }
                    }
                }

                > .checked {
                    display: flex;
                    margin-top: 16px;
        
                    > .icon {
                        margin-right: 10px;
                    }
        
                    > .text {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        color: $darkGray;
                    }
                }

                > .infoRows {
                    margin-top: 16px;
        
                    > .infoRow {
                        display: flex;
                        justify-content: space-between;
        
                        &:not(:first-child) {
                            margin-top: 16px;
                        }
        
                        > .title {
                            font-size: 16px;
                            line-height: 24px;
                            color: #7E808F;
                        }
        
                        > .value {
                            text-align: right;
                            font-size: 16px;
                            line-height: 24px;
                            color: $darkGray;
                        }
                    }
                }

                > .line {
                    height: 1px;
                    background-color: $borderLightGray;
                    margin: 16px 0;
                }

                &:last-child {
                    .line {
                        display: none;
                    }
                }
            }
        }
    }
}