@import "../../var.scss";
@import "../common.scss";

.trialMode {
  background-color: $primary01;
  display: flex;
  align-items: center;
  justify-content: center;
  @extend .page;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &_logo {
      margin-bottom: 56px;
      width: 140px;
      height: 32px;
    }

    &__form {
      padding: 40px;
      background-color: white;
      border-radius: 16px;
      max-width: 450px;
      width: 100%;
      z-index: 2;
      display: flex;
      align-items: center;
      flex-direction: column;

      .icon {
        margin-bottom: 40px;
      }

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: $darkGray;
      }

      .text {
        padding-top: 8px;
        font-size: 16px;
        line-height: 24px;
        color: $darkGray;
        text-align: center;
      }

      .btn {
        margin-top: 32px;
        width: 100%;
        height: 56px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $white;
      }
    }
  }

  &_back {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
  }
}