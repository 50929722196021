
@import '../../var.scss';

.TrialModeModalContainer {
  width: 100%;

  > .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .icon {
      margin-top: 24px;
    }

    > .textInfo {
      margin-top: 32px;
      margin-bottom: 24px;
      text-align: center;

      > .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: $darkGray;
        padding-bottom: 24px;
      }
    }
  }
}