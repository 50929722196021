.dropdown {
  position: relative;

  &__clickZone {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &_arrow {
      transition: all 0.15s ease-in-out;
      margin-left: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
    }
  }

  &__menu {
    &_paper {
      padding: 16px !important;
      border-radius: 8px !important;
      box-shadow: 0px 8px 16px rgba(194, 198, 220, 0.25) !important;
      
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &_list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100% !important;

      li {
        width: 100%;
        opacity: 1 !important;
        padding: 6px 2px !important;
      }
    }
  }

  &__btn {
    cursor: pointer;
  }
}
