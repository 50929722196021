@import '../../var.scss';

.readersModal {
    width: 100%;
    display: flex;
    align-items: flex-start;

    > .readers {
        width: 100%;

        > .title {
            display: block;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: $darkGray;
        }

        > .readerList {
            margin-top: 24px;

            > .reader {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 24px;

                > .leftSide {
                    display: flex;
                    align-items: center;

                    .photo {
                        object-fit: cover;
                        min-width: 46px;
                        min-height: 46px;
                        max-width: 46px;
                        max-height: 46px;
                        width: 46px;
                        height: 46px;
                        border: 1px solid $white;
                        border-radius: 50%;
                    }
                    
                      .noPhoto {
                        min-width: 46px;
                        min-height: 46px;
                        max-width: 46px;
                        max-height: 46px;
                        width: 46px;
                        height: 46px;
                        border: 1px solid $white;
                        border-radius: 50%;
                        color: $white;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 24px;
                        text-align: center;
                    }

                    .photo, .noPhoto {
                        margin-right: 8px;
                    }
    
                    > .info {
                        display: flex;
                        flex-direction: column;

                        > .row {
                            display: flex;
                            align-items: center;
                            
                            > .name {
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 24px;
                                color: $darkGray;
                            }
    
                            > .icon {
                                margin-left: 4px;
                            }
    
                            > .contact {
                                font-size: 14px;
                                line-height: 22px;
                                color: $darkGray;
                            }
                        }
                    }
                }
    
                > .rightSide {
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    
                    > .time {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        color: $gray;
                    }
    
                    > .date {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        color: $gray;
                    }
                }
            }
        }
    }
}

.historyCard {
    position: relative;
    display: flex;
    border-radius: 16px;
    box-shadow: 0px 0px 20px -3px rgba(216, 216, 226, 0.2);
    background-color: $white;
    cursor: pointer;

    transition: all 0.15s;

    > .status {
        display: flex;
        width: 40px;
        min-height: 88px;
        background: $deleteRed;
        border-radius: 16px 0px 0px 16px;

        > .icon {
            margin: auto;
        }
    }

    > .ureadMessages {
        position: absolute;
        top: -6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background-color: $deleteRed;
        border-radius: 50%;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        color: $white;
    }

    > .content {
        width: 100%;
        position: relative;
        display: flex;
        padding: 16px;

        > .info {
            width: 100%;

            > .header {
                width: 100%;
                display: flex;
                justify-content: space-between;

                > .headerLeft{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;

                    > .top {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;

                        > .title {
                            > .name {
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 24px;
                                color: $darkGray;
                            }

                            > .draft {
                                margin-left: 8px;
                                font-size: 14px;
                                line-height: 24px;
                                color: $gray;
                            }
                        }
                        
                        > .right {
                            display: flex;
                            align-items: center;

                            > .photoCellsRow {

                            }
                            
                            > .iconDone {
                                margin-left: 24px;
                            }
                        }
                    }

                    > .statusRow {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 4px;
    
                        > .left {
                            display: flex;
                            align-items: center;
                            margin-right: 16px;
    
                            > .injury {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 22px;
                                color: $darkGray;
                            }
        
                            > .line {
                                height: 16px;
                                width: 1px;
                                background-color: $darkGray;
                                margin: 0 16px;
                            }
        
                            > .priority {
                                display: flex;
                                align-items: center;
                                margin-right: 16px;
                                
                                > .text {
                                    margin-right: 8px;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 22px;
                                    color: $darkGray;
                                }
                            }
        
                            > .date {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 22px;
                                color: $gray;
                                white-space: nowrap;
                            }
                        }
    
                        > .right {
                            > .update {
                                font-size: 14px;
                                line-height: 22px;
                                color: $darkGray;
    
                                > .date {
                                    font-weight: 500;
                                    white-space: nowrap;
                                }
                            }
                        }
    
                        
                    }
                }

                > .headerRight {
                    display: flex;
                    
                    & > .deleteWrapper {
                        margin-right: 24px;

                        > .deleteBtn {
                            padding: 14px;
                            border-color: $borderGray;
                            background-color: $white;
    
                            &:hover {
                                background-color: $lightGray;
                            }
                        }
                    }
                    

                    > .continueBtn {
                        padding: 12px 43px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: $white;
                        background-color: $primary01;

                        &:hover {
                            background-color: $primary02;
                        }
                    }
                }
            }

            > .description {
                position: relative;
                display: flex;
                flex-direction: column;
                margin-top: 24px;

                > .text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: $darkGray;
                }

                > .updateTime {
                    margin-top: 8px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: $darkGray;

                    > .bold {
                        font-weight: 600;
                    }
                }

                > .bottom {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    > .updator {
                        margin-top: 8px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                        color: $darkGray;
    
                        > .bold {
                            font-weight: 600;
                        }
                    }

                    > .chat {
                        display: flex;
                        align-items: center;
                        
                        > .icon {
                            margin-right: 10px;
                        }

                        > .text {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 24px;
                            color: $primary01;
                        }
                    }
                }

                > .documents {
                    margin-top: 8px;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 8px;

                    > .document {
                        display: flex;
                        align-items: center;
                        margin-top: 14px;
        
                        &:hover {
                            cursor: pointer;
                        }
        
                        > .icon {
                            margin-right: 8px;
                        }
        
                        > .text {
                            font-size: 16px;
                            line-height: 22px;
                            text-decoration-line: underline;
                            color: $darkGray;
                        }
                    }
                }

                > .photos {
                    margin-top: 16px;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 8px;

                    > .documentPhoto {
                        width: 220px;
                        height: 120px;
                        object-fit: cover;
                    }
                }

                > .copy {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    bottom: 0;

                    > .icon {
                        width: 22px;
                        height: 22px;
                        margin-right: 4px;
                        
                        &:hover {
                         cursor: pointer;
                        }
                    }

                    > .text {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                        color: $primary01;
                    }
                }
            }
        }
    }
}

.photo, .noPhoto {
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    margin-right: 16px;
    border-radius: 50%;
    object-fit: cover;
}

.noPhoto {
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $white;
}